import { useTranslates } from 'providers/translates';
import { useRedirectAndScroll } from 'hooks';
import { useAuth } from 'providers/auth-provider';
import { CreateQuiltButton } from '../CreateQuiltButton';
import { RoutesName } from 'routs';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';
import MenuIcon from '@mui/icons-material/Menu';
import { useEffect, useRef } from 'react';

type Props = {
  headerItemsController: {
    language: boolean;
    howToUse: boolean;
    support: boolean;
    myAccount: boolean;
    donate: boolean;
    aboutUs: boolean;
    contactUs: boolean;
    icons: boolean;
  };
  actionLogout: () => void;
  headerButtonsRef: React.MutableRefObject<HTMLDivElement | null>;
};

const MobileMenu: React.FC<Props> = ({ headerItemsController, actionLogout, headerButtonsRef }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { translates } = useTranslates();
  const { onlyRedirect } = useRedirectAndScroll({});
  const { isAuthorized, user } = useAuth();
  const menuRef = useRef<HTMLUListElement | null>(null);
  const menuButtonRef = useRef<HTMLDivElement | null>(null);

  const closeMenu = () => {
    (headerButtonsRef.current as HTMLDivElement).style.display = 'flex';
    (menuRef.current as HTMLUListElement).style.display = 'none';
  };

  const handleToggleMenu = () => {
    (headerButtonsRef.current as HTMLDivElement).style.display =
      (headerButtonsRef.current as HTMLDivElement).style.display === 'flex' ? 'none' : 'flex';
    (menuRef.current as HTMLUListElement).style.display =
      (menuRef.current as HTMLUListElement).style.display === 'none' ? 'flex' : 'none';
  };

  const handleTouchOutsideMenu = (event: TouchEvent) => {
    if (!menuRef.current || !menuButtonRef.current) {
      return;
    }

    if (
      !menuButtonRef.current.contains(event.target as Node) &&
      !menuRef.current.contains(event.target as Node)
    ) {
      closeMenu();
    }
  };

  useEffect(() => {
    document.addEventListener('touchstart', handleTouchOutsideMenu);

    return () => {
      document.removeEventListener('touchstart', handleTouchOutsideMenu);
    };
  }, []);

  useEffect(() => {
    closeMenu();
  }, [location]);

  return (
    <div className={styles['menu-toggle']}>
      <div ref={menuButtonRef} onClick={handleToggleMenu}>
        <MenuIcon
          sx={{
            color: '#4ba5c6',
            width: '50px',
            height: '50px',
          }}
        />
      </div>
      <ul ref={menuRef} className={styles['header__menu-items']} style={{ display: 'none' }}>
        {isAuthorized && (
          <li className={styles['header__menu-item']} onClick={() => onlyRedirect('/profile')}>
            {user!.userName}
          </li>
        )}
        <li className={styles['header__menu-item']}>
          <CreateQuiltButton />
        </li>
        <li
          className={styles['header__menu-item']}
          onClick={() => onlyRedirect(RoutesName.SearchQuilt)}
        >
          {translates?.header?.searchQuilts || 'Search Quilts'}
        </li>
        {isAuthorized && headerItemsController.myAccount && (
          <li className={styles['header__menu-item']} onClick={() => onlyRedirect('/profile')}>
            My Account
          </li>
        )}
        {headerItemsController.howToUse && (
          <li className={styles['header__menu-item']} onClick={() => onlyRedirect('/howtouse')}>
            How To Use
          </li>
        )}
        {headerItemsController.support && (
          <li className={styles['header__menu-item']} onClick={() => onlyRedirect('/resources')}>
            Resources
          </li>
        )}
        <li>
          {isAuthorized ? (
            <button className="button header__button-auth -blue" onClick={actionLogout}>
              {translates?.header?.logout || 'Logout'}
            </button>
          ) : (
            <button
              className="button header__button-auth -green"
              onClick={() => navigate('/login')}
            >
              {translates?.header?.signIn || 'Sign In'}
            </button>
          )}
        </li>
      </ul>
    </div>
  );
};

export { MobileMenu };
