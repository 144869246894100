import { useEffect, useState, FC, ChangeEvent } from 'react';

import { fetchQuilts, fetchUsers } from 'api/http-client/queries/administration';
import { getProfile } from 'api/http-client/queries/profileQueries';
import { TProfile } from 'api/http-client/profileTypes';
import { useAuth } from 'providers/auth-provider';
import { useAppDispatch, useAppSelector, useRedirectAndScroll } from 'hooks';
import { removeUsers, setUsers } from 'store/reducers/adminUserSlice';
import { removeQuilts, setQuilts } from 'store/reducers/adminQuiltSlice';
import { setOpen } from 'store/reducers/notifySlice';

import { UserTable } from './UserTable';
import { QuiltTable } from './QuiltTable';
import { SearchBox } from './SearchBox';
import { SearchBoxUsers } from './SearchBoxUsers';
import { SortBy, UserSortField } from './UserTable/const';
import { QuiltSortField } from './QuiltTable/const';

import styles from './adminDashboard.module.scss';
import { ValueOf } from 'common/generic/value-of';

export interface UserSearchModel {
  email?: string;
  lastName?: string;
  firstName?: string;
}

const AdminDashboard: FC = () => {
  const [userSearch, setUserSearch] = useState<UserSearchModel>({});
  const [quiltSearch, setQuiltSearch] = useState('');

  const [totalUsers, setTotalUsers] = useState(0);
  const [totalQuilts, setTotalQuilts] = useState(0);

  const [userPage, setUserPage] = useState(0);
  const [userRowsPerPage, setUserRowsPerPage] = useState(5);

  const [quiltPage, setQuiltPage] = useState(0);
  const [quiltRowsPerPage, setQuiltRowsPerPage] = useState(5);

  const [userLoading, setUserLoading] = useState(false);
  const [quiltLoading, setQuiltLoading] = useState(false);

  const [currentUser, setCurrentUser] = useState<TProfile | null>(null);

  const dispatch = useAppDispatch();
  const adminUsersStore = useAppSelector((state) => state.adminUser);
  const adminQuiltsStore = useAppSelector((state) => state.adminQuilt);

  const { isAuthorized } = useAuth();
  const { onlyScroll, onlyRedirect } = useRedirectAndScroll({});

  useEffect(() => {
    if (!isAuthorized) {
      onlyRedirect('/login', true);
    }
  }, [isAuthorized]);

  useEffect(() => {
    getProfile()
      .then((data) => {
        setCurrentUser(data);
        if (data?.isAdmin !== true) {
          onlyRedirect('/profile', true);
        }
      })
      .catch((error) => {
        dispatch(setOpen(error.message));
        onlyRedirect('/profile', true);
      });
  }, [dispatch]);

  useEffect(() => {
    searchUserClick();
    searchQuiltClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ********* Fetch users ********* //

  const fetchUsersFn = ({
    filter = {},
    page = 1,
    limit = 5,
    sortBy = SortBy.ASC,
    sortField = UserSortField.EMAIL,
  }: {
    filter?: UserSearchModel;
    page?: number;
    limit?: number;
    sortBy?: ValueOf<typeof SortBy>;
    sortField?: ValueOf<typeof UserSortField>;
  }) => {
    setUserLoading(true);
    fetchUsers({
      filter,
      page,
      limit,
      sortBy,
      sortField,
      includeData: true,
      getAdminStatus: true,
    })
      .then((data) => {
        if (data.returned?.value.length === 0) {
          dispatch(setOpen('No user found'));
          dispatch(removeUsers());
          setUserPage(0);
          setTotalUsers(0);
        } else {
          setTotalUsers(data.returned.total);
          dispatch(setUsers(data.returned.value));
        }
      })
      .catch((error) => {
        dispatch(setOpen(error.message || error.errors?.[0]));
      })
      .finally(() => {
        setUserLoading(false);
      });
  };

  const searchUserClick = () => {
    fetchUsersFn({
      filter: userSearch,
      limit: userRowsPerPage,
    });
  };

  const refreshUserClick = () => {
    setUserSearch({});
    setUserPage(0);
    setTotalUsers(0);
    fetchUsersFn({
      limit: userRowsPerPage,
    });
  };

  const handleUserChangePage = (
    newPage: number,
    sortBy: ValueOf<typeof SortBy>,
    sortField: ValueOf<typeof UserSortField>
  ) => {
    setUserPage(newPage);
    onlyScroll('userTable');
    
    fetchUsersFn({
      filter: userSearch,
      page: newPage + 1,
      limit: userRowsPerPage,
      sortBy,
      sortField
    });
  };

  const handleUserChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setUserRowsPerPage(parseInt(event.target.value, 10));
    setUserPage(0);
    onlyScroll('userTable');
    fetchUsersFn({
      filter: userSearch,
      limit: parseInt(event.target.value, 10),
    });
  };

  const onUserRefetch = ({ sortBy, sortField }: { sortBy: ValueOf<typeof SortBy>; sortField: string }) => {
    // if (sortField === 'email') {
    //   sortField = UserSortField.EMAIL;
    // } else if (sortField === 'dateCreated') {
    //   sortField = UserSortField.DATE_CREATED;
    // } else return;

    fetchUsersFn({
      filter: userSearch,
      limit: userRowsPerPage,
      sortBy: sortBy === 'asc' ? SortBy.ASC : SortBy.DESC,
      sortField: sortField as ValueOf<typeof UserSortField>,
    });
  };

  // ********* Fetch quilts ********* //

  const fetchQuiltsFn = ({
    filter = '',
    page = 1,
    limit = 5,
    sortBy = SortBy.DESC,
    sortField = QuiltSortField.DATE_CREATED,
  }: {
    filter?: string;
    page?: number;
    limit?: number;
    sortBy?: ValueOf<typeof SortBy>;
    sortField?: ValueOf<typeof QuiltSortField> | ValueOf<typeof UserSortField>;
  }) => {
    const queryArgs = filter.split(' ');
    const [firstName = '', lastName = ''] = queryArgs;

    setQuiltLoading(true);
    fetchQuilts({ firstName, lastName, page, limit, sortBy, sortField })
      .then((data) => {
        if (data.returned?.value.length === 0) {
          dispatch(setOpen('No quilt found'));
          dispatch(removeQuilts());
          setTotalQuilts(0);
          setQuiltPage(0);
        } else {
          setTotalQuilts(data.returned.total);
          dispatch(setQuilts(data.returned.value));
        }
      })
      .catch((error) => {
        dispatch(setOpen(error.message || error.errors?.[0]));
      })
      .finally(() => {
        setQuiltLoading(false);
      });
  };

  const searchQuiltClick = () => {
    fetchQuiltsFn({
      filter: quiltSearch,
      limit: quiltRowsPerPage,
    });
  };

  const refreshQuiltClick = () => {
    setQuiltSearch('');
    setTotalQuilts(0);
    setQuiltPage(0);
    fetchQuiltsFn({
      limit: quiltRowsPerPage,
    });
  };

  const handleQuiltChangePage = (
    newPage: number,
    sortBy: ValueOf<typeof SortBy>,
    sortField: ValueOf<typeof QuiltSortField>
  ) => { 
    setQuiltPage(newPage);
    fetchQuiltsFn({
      filter: quiltSearch,
      page: newPage + 1,
      limit: quiltRowsPerPage,
      sortBy,
      sortField
    });
  };

  const handleQuiltChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setQuiltRowsPerPage(parseInt(event.target.value, 10));
    setQuiltPage(0);
    fetchQuiltsFn({
      filter: quiltSearch,
      limit: parseInt(event.target.value, 10),
    });
  };

  const onQuiltRefetch = ({ sortBy, sortField }: { sortBy: ValueOf<typeof SortBy>; sortField: string }) => {
    fetchQuiltsFn({
      filter: quiltSearch,
      limit: quiltRowsPerPage,
      sortBy: sortBy === SortBy.ASC ? SortBy.ASC : SortBy.DESC,
      sortField: sortField as ValueOf<typeof QuiltSortField>,
    });
  };

  const handleSetUserSearch = (value: string, key: keyof UserSearchModel) => {
    setUserSearch((state) => {
      return {
        ...state,
        [key]: value,
      };
    });
  };

  return (
    <div>
      <div className={styles.admin_container}>
        <h1 className="bigTitle">Users</h1>
        <SearchBoxUsers
          value={userSearch}
          loading={userLoading}
          onChangeClick={handleSetUserSearch}
          searchClick={searchUserClick}
          refreshClick={refreshUserClick}
        />
        <div id="userTable" />
        <UserTable
          visibleRows={adminUsersStore}
          page={userPage}
          count={totalUsers}
          rowsPerPage={userRowsPerPage}
          loading={userLoading}
          currentUserId={currentUser?.id}
          handleChangePage={handleUserChangePage}
          handleChangeRowsPerPage={handleUserChangeRowsPerPage}
          onUserRefetch={onUserRefetch}
        />

        <h1 className="bigTitle">Quilts</h1>
        <SearchBox
          value={quiltSearch}
          placeholder="Search quilt"
          loading={quiltLoading}
          onChangeClick={setQuiltSearch}
          searchClick={searchQuiltClick}
          refreshClick={refreshQuiltClick}
        />
        <QuiltTable
          visibleRows={adminQuiltsStore}
          page={quiltPage}
          count={totalQuilts}
          rowsPerPage={quiltRowsPerPage}
          loading={quiltLoading}
          handleChangePage={handleQuiltChangePage}
          handleChangeRowsPerPage={handleQuiltChangeRowsPerPage}
          onQuiltRefetch={onQuiltRefetch}
        />
      </div>
    </div>
  );
};

export default AdminDashboard;
