interface IQuiltTableHeader {
  id: string;
  label: string;
  width: string;
  align: 'inherit' | 'left' | 'center' | 'right' | 'justify';
}

export const QuiltTableHeader: IQuiltTableHeader[] = [
  {
    id: 'firstName',
    label: 'Quilt Name',
    width: 'auto',
    align: 'center',
  },
  {
    id: 'ownerName',
    label: 'Quilt owner',
    width: 'auto',
    align: 'center',
  },
  {
    id: 'dateCreated',
    label: 'Date Created',
    width: '450px',
    align: 'center',
  },
  {
    id: 'datePublished',
    label: 'Date Published',
    width: 'auto',
    align: 'center',
  },
  {
    id: 'dateUpdated',
    label: 'Last Updated',
    width: 'auto',
    align: 'center',
  },
  {
    id: 'isPublic',
    label: 'Display settings',
    width: 'auto',
    align: 'center',
  },
  {
    id: 'tributesEnabled',
    label: 'Tributes & Condolences',
    width: 'auto',
    align: 'center',
  },
  {
    id: 'quiltStatus',
    label: 'Quilt Status',
    width: 'auto',
    align: 'center',
  },
  {
    id: 'button',
    label: 'More',
    width: 'auto',
    align: 'center',
  },
];

export const QuiltSortField = {
  FIRST_NAME: 'firstName',
  OWNER_NAME: 'ownerName',
  DATE_CREATED: 'dateCreated',
  DATE_PUBLISHED: 'datePublished',
  DATE_UPDATED: 'dateUpdated',
  PUBLIC: 'isPublic',
  TRIBUTES_ENABLED: 'tributesEnabled',
  QUILT_STATUS: 'quiltStatus',
} as const;

export const QuiltStatus = {
  ACTIVE: 'Active',
  LOCKED: 'Locked',
  DELETED: 'Deleted'
} as const;
