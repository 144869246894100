import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from '@mui/material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import HelpIcon from '@mui/icons-material/Help';
import ReCAPTCHA from 'react-google-recaptcha';

import { useAppDispatch, useRedirectAndScroll } from 'hooks';
import { checkEmailExist } from 'api/http-client/queries/accounts';
import { useAuth } from 'providers/auth-provider';
import { useTranslates } from 'providers/translates';
import { Loader } from 'components/Loader/Loader';
import {
  colors,
  containOneDigitRegex,
  containOneSpecialCharRegex,
  containOneLowerCaseRegex,
  containOneUpperCaseRegex,
  emailRegex,
  passwordRegex,
  postalCodeRegex,
} from 'utilities';
import { DarkTooltip } from 'components/Tooltips/CustomTooltips';
import { setOpen } from 'store/reducers/notifySlice';

import styles from './signUp.module.scss';

interface SignUpForm {
  firstName: string;
  lastName: string;
  email: string;
  confirmEmail: string;
  password: string;
  confirmPassword: string;
  postalCode: string;
}

const SignUp: React.FC = () => {
  const { register, isAuthorized, user } = useAuth();
  const { translates } = useTranslates();
  const { onlyRedirect } = useRedirectAndScroll({});
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const dispatch = useAppDispatch();

  const [isRegistered, setRegistered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAgreedPrivacy, setIsAgreed] = useState(false);
  const [isShowPrivacyModal, setIsShowPrivacy] = useState(false);
  const [showPass_1, setShowPass_1] = useState(false);
  const [showPass_2, setShowPass_2] = useState(false);
  const [termsIsRed, setTermsIsRed] = useState(false);
  const [commonError, setCommonError] = useState('');
  const [emailExistError, setEmailExistError] = useState(false);
  const [passwordError, setPasswordError] = useState('');

  useEffect(() => {
    if (isAuthorized && !user?.isInvitedUser) {
      onlyRedirect('/', true);
    }
  }, [isAuthorized]);

  const { control, handleSubmit, watch } = useForm({
    mode: 'onBlur',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      confirmEmail: '',
      password: '',
      confirmPassword: '',
      postalCode: '',
      captcha: false,
    },
  });

  const onSubmit = (data: SignUpForm) => {
    const { firstName, lastName, email, password, postalCode } = data;
    setCommonError('');
    if (!isAgreedPrivacy) {
      setIsShowPrivacy(true);
      setTermsIsRed(true);
      return;
    }

    setIsLoading(true);
    register({
      email,
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      password,
      postalCode,
      province: '',
      token: params.get('token') || '',
    })
      .then((resp) => {
        resp.returned.quiltId && onlyRedirect(`/update/${resp.returned.quiltId}`);
        setRegistered(true);
      })
      .catch((res) => {
        if (emailExistError) {
          setCommonError(`An account using the email '${email}' already exists.`);
          dispatch(setOpen(`An account using the email '${email}' already exists. Sign in.`));
        } else {
          setCommonError(res?.errors?.join(', ') || 'Unable to register, please try again later');
          dispatch(setOpen(res?.errors?.join(', ') || 'Unable to register, please try again later'));
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const checkEmail = (email: string) => {
    setEmailExistError(false);
    if (emailRegex.test(email)) {
      checkEmailExist(email)
        .then((res) => {
          if (!res.returned?.isFree) {
            setEmailExistError(true);
          }
        })
        .catch((err) => {
          dispatch(setOpen('Error checking email'));
        });
    }
  };

  const checkPassword = (password: string) => {
    setPasswordError('');
    if (password.length === 0) {
      setPasswordError('Password is required');
      return;
    }
    if (password.length < 6) {
      setPasswordError('Password must be at least 6 characters');
      return;
    }
    if (!containOneDigitRegex.test(password)) {
      setPasswordError('Password must contain at least one digit');
      return;
    }
    if (!containOneLowerCaseRegex.test(password)) {
      setPasswordError('Password must contain at least one lowercase letter');
      return;
    }
    if (!containOneUpperCaseRegex.test(password)) {
      setPasswordError('Password must contain at least one uppercase letter');
      return;
    }
    if (!containOneSpecialCharRegex.test(password)) {
      setPasswordError('Password must contain at least one special character');
      return;
    }
  };

  return (
    <section className={styles.signUp_section}>
      <div className={`center ${styles.create_quilt}`}>
        <h1 className="bigTitle">
          {translates?.signUp?.createQuiltTitle ||
            'Create a Memorial Quilt Square in 4 Simple Steps'}
        </h1>
        <div className={styles.steps}>
          <div className={styles.step__item}>
            <div className={styles.step__num}>1</div>
            <div className={styles.step__info}>
              <h5 className="defaultTitle1">
                {translates?.signUp?.stepTitle1 || 'Start a Quilt Square'}
              </h5>
              <p className="defaultText">
                {translates?.signUp?.stepContent1 ||
                  'Set up an account to start a quilt square in memory of someone.'}
              </p>
            </div>
          </div>
          <div className={styles.step__item}>
            <div className={styles.step__num}>2</div>
            <div className={styles.step__info}>
              <h5 className="defaultTitle1">{translates?.signUp?.stepTitle2 || 'Invite Others'}</h5>
              <p className="defaultText">
                {translates?.signUp?.stepContent2 ||
                  'Invite people to contribute to your memorial quilt square.'}
              </p>
            </div>
          </div>
          <div className={styles.step__item}>
            <div className={styles.step__num}>3</div>
            <div className={styles.step__info}>
              <h5 className="defaultTitle1">
                {translates?.signUp?.stepTitle3 || 'Add Photos and Memories'}
              </h5>
              <p className="defaultText">
                {translates?.signUp?.stepContent3 ||
                  'Add photos, information, and memories about your person.'}
              </p>
            </div>
          </div>
          <div className={styles.step__item}>
            <div className={styles.step__num}>4</div>
            <div className={styles.step__info}>
              <h5 className="defaultTitle1">
                {translates?.signUp?.stepTitle4 || 'Share Your Quilt Square'}
              </h5>
              <p className="defaultText">
                {translates?.signUp?.stepContent4 ||
                  'Choose to share your quilt square publicly or with only friends and family.'}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={`center ${styles.signUpBox}`}>
        {isRegistered && (
          <p style={{ textAlign: 'center', fontWeight: 500 }}>
            {translates?.signUp?.verifyAccountMessage ||
              'Thanks for registering. Please check your email to verify your account.'}
          </p>
        )}
        {!isRegistered && (
          <>
            <h2 className="text-center defaultTitle">
              {translates?.signUp?.readyTitle || 'Ready to begin? Set up your free account.'}
            </h2>
            <p className={styles.signUpBox__link}>
              Already have an account? <Link to={'/login'}>Sign In</Link>.
            </p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container rowSpacing={{ xs: 0, md: 1 }} columnSpacing={4}>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="firstName"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <div className={`inputWithTitle ${styles.input_withTitle}`}>
                        <span>{translates?.signUp?.firstName || 'First name'}</span>
                        <TextField
                          className="customInput"
                          error={!!error}
                          fullWidth
                          value={value}
                          onChange={(e) => onChange(e.target.value)}
                          onBlur={onBlur}
                          helperText={
                            !!error
                              ? `${translates?.login?.emailHelperTextError || 'First name is required'}`
                              : ''
                          }
                          FormHelperTextProps={{ style: { fontSize: 16 } }}
                        />
                      </div>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="lastName"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <div className={`inputWithTitle ${styles.input_withTitle}`}>
                        <span>{translates?.signUp?.lastName || 'Last name'}</span>
                        <TextField
                          className="customInput"
                          error={!!error}
                          fullWidth
                          value={value}
                          onChange={(e) => onChange(e.target.value)}
                          onBlur={onBlur}
                          helperText={
                            !!error
                              ? `${translates?.login?.emailHelperTextError || 'Last name is required'}`
                              : ''
                          }
                          FormHelperTextProps={{ style: { fontSize: 16 } }}
                        />
                      </div>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: { value: true, message: 'Email is required' },
                      pattern: emailRegex,
                    }}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <div className={`inputWithTitle ${styles.input_withTitle}`}>
                        <span>{translates?.signUp?.userEmail || 'Your email'}</span>
                        <TextField
                          className="customInput"
                          error={!!error || emailExistError}
                          fullWidth
                          value={value}
                          onChange={(e) => onChange(e.target.value.trim())}
                          onBlur={(e) => {
                            checkEmail(e.target.value.trim());
                            onBlur();
                          }}
                          helperText={
                            !!error
                              ? `${translates?.login?.emailHelperTextError || error.message || 'Invalid email address'}`
                              : emailExistError
                                ? 'Email already exists'
                                : ''
                          }
                          FormHelperTextProps={{ style: { fontSize: 16 } }}
                        />
                      </div>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="confirmEmail"
                    control={control}
                    rules={{
                      required: { value: true, message: 'Email is required' },
                      validate: (val: string) => {
                        if (watch('email') !== val) {
                          return 'Email address does not match';
                        }
                      },
                    }}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <div className={`inputWithTitle ${styles.input_withTitle}`}>
                        <span>{translates?.signUp?.confirmEmail || 'Confirm your email'}</span>
                        <TextField
                          className="customInput"
                          error={!!error}
                          fullWidth
                          value={value}
                          onChange={(e) => onChange(e.target.value.trim())}
                          onBlur={onBlur}
                          helperText={
                            !!error
                              ? `${translates?.login?.emailHelperTextError || error.message || 'Invalid email address'}`
                              : ''
                          }
                          FormHelperTextProps={{ style: { fontSize: 16 } }}
                        />
                      </div>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="password"
                    control={control}
                    rules={{
                      required: { value: true, message: 'Password is required' },
                      pattern: passwordRegex,
                    }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <div className={`inputWithTitle ${styles.input_withTitle}`}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                          <span>{translates?.signUp?.userPassword || 'Password'}</span>
                          <DarkTooltip
                            title={
                              translates?.login?.passwordTooltipRequirements ||
                              'Password must be at least 6 characters and must include one lowercase letter, one uppercase letter, one number, and one special character (e.g., $, #, @).'
                            }
                          >
                            <HelpIcon sx={{ color: colors.blue }} />
                          </DarkTooltip>
                        </div>
                        <TextField
                          className="customInput"
                          error={!!error || !!passwordError}
                          fullWidth
                          value={value}
                          onChange={(e) => onChange(e.target.value.trim())}
                          onBlur={(e) => checkPassword(e.target.value.trim())}
                          helperText={
                            !!passwordError
                              ? passwordError
                              : !!error
                                ? `${translates?.login?.passwordHelperTextError || error.message || 'Incorrect password'}`
                                : ''
                          }
                          FormHelperTextProps={{ style: { fontSize: 16 } }}
                          type={showPass_1 ? 'text' : 'password'}
                          InputProps={{
                            endAdornment: (
                              <DarkTooltip
                                title={
                                  showPass_1
                                    ? `${translates?.login?.passwordTooltipTitleHide || 'Hide password'}`
                                    : `${translates?.login?.passwordTooltipTitleShow || 'Show password'}`
                                }
                              >
                                <IconButton onClick={() => setShowPass_1(!showPass_1)}>
                                  {showPass_1 ? (
                                    <VisibilityOffIcon />
                                  ) : (
                                    <VisibilityIcon color="inherit" />
                                  )}
                                </IconButton>
                              </DarkTooltip>
                            ),
                          }}
                        />
                      </div>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="confirmPassword"
                    control={control}
                    rules={{
                      required: { value: true, message: 'Password is required' },
                      validate: (val: string) => {
                        if (watch('password') !== val) {
                          return 'Password does not match';
                        }
                      },
                    }}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <div className={`inputWithTitle ${styles.input_withTitle}`}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                          <span>
                            {translates?.signUp?.confirmPassword || 'Confirm your password'}
                          </span>
                          <DarkTooltip
                            title={
                              translates?.login?.passwordTooltipRequirements ||
                              'Password must be at least 6 characters and must include one lowercase letter, one uppercase letter, one number, and one special character (e.g., $, #, @).'
                            }
                          >
                            <HelpIcon sx={{ color: colors.blue }} />
                          </DarkTooltip>
                        </div>
                        <TextField
                          className="customInput"
                          error={!!error}
                          fullWidth
                          value={value}
                          onChange={(e) => onChange(e.target.value.trim())}
                          onBlur={onBlur}
                          helperText={
                            !!error
                              ? `${translates?.login?.passwordHelperTextError || error.message || 'Incorrect password'}`
                              : ''
                          }
                          FormHelperTextProps={{ style: { fontSize: 16 } }}
                          type={showPass_2 ? 'text' : 'password'}
                          InputProps={{
                            endAdornment: (
                              <DarkTooltip
                                title={
                                  showPass_2
                                    ? `${translates?.login?.passwordTooltipTitleHide || 'Hide password'}`
                                    : `${translates?.login?.passwordTooltipTitleShow || 'Show password'}`
                                }
                              >
                                <IconButton onClick={() => setShowPass_2(!showPass_2)}>
                                  {showPass_2 ? (
                                    <VisibilityOffIcon />
                                  ) : (
                                    <VisibilityIcon color="inherit" />
                                  )}
                                </IconButton>
                              </DarkTooltip>
                            ),
                          }}
                        />
                      </div>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="postalCode"
                    control={control}
                    rules={{
                      required: { value: true, message: 'Postal code is required' },
                      pattern: postalCodeRegex,
                    }}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <div className={`inputWithTitle ${styles.input_withTitle}`}>
                        <span>{translates?.signUp?.postalCode || 'Postal code'}</span>
                        <TextField
                          className="customInput"
                          error={!!error}
                          fullWidth
                          value={value}
                          onChange={(e) => onChange(e.target.value.trim())}
                          onBlur={onBlur}
                          placeholder="K1A 0B1"
                          helperText={
                            !!error
                              ? `${translates?.login?.emailHelperTextError || error.message || 'For example: K1A 0B1'}`
                              : ''
                          }
                          FormHelperTextProps={{ style: { fontSize: 16 } }}
                        />
                      </div>
                    )}
                  />
                </Grid>
              </Grid>
              {commonError && (
                <div className={styles.error_block}>
                  {commonError}{' '}
                  <Link className={styles.link} to={'/login'}>
                    {' '}
                    Sign in
                  </Link>
                  .
                </div>
              )}
              <Grid item xs={12} className={styles.reCaptcha}>
                <Controller
                  name="captcha"
                  control={control}
                  rules={{
                    required: { value: true, message: 'Please confirm that you are not a robot' },
                  }}
                  render={({ field: { onChange }, fieldState: { error } }) => (
                    <>
                      {process.env.REACT_APP_RECAPTCHA_SECRET_KEY && (
                        <ReCAPTCHA
                          onChange={onChange}
                          sitekey={process.env.REACT_APP_RECAPTCHA_SECRET_KEY}
                        />
                      )}
                      <p className={styles.errorMessage}>{error?.message}</p>
                    </>
                  )}
                />
              </Grid>
              <div className={styles.submit_block}>
                <div className={styles.signUpInput}>
                  <label className={styles.label}>
                    <input
                      type="checkbox"
                      name="memorial"
                      value="Private"
                      disabled={isLoading}
                      checked={isAgreedPrivacy}
                      onChange={() => {
                        if (isAgreedPrivacy) {
                          setIsAgreed(false);
                        } else {
                          setIsShowPrivacy(true);
                        }
                      }}
                    />
                    <span style={{ fontSize: '18px', color: termsIsRed ? colors.red : '#24476A' }}>
                      {translates?.signUp?.signUpConsent ||
                        'I have reviewed the privacy terms and conditions'}
                    </span>
                  </label>
                </div>

                <button className="customButton" type="submit" disabled={isLoading}>
                  {translates?.signUp?.signUp || 'Sign Up >'}
                </button>
              </div>
            </form>
          </>
        )}
        <Dialog
          fullWidth
          maxWidth="lg"
          open={isShowPrivacyModal}
          onClose={() => setIsShowPrivacy(false)}
        >
          <DialogTitle variant="h4" fontWeight={'bold'} className="defaultTitle1">
            {translates?.signUp?.privacyTermsAndConditionsTitle ||
              'Canadian Healing Quilt Privacy and Use Notice'}
          </DialogTitle>
          <DialogContent sx={{ maxHeight: '400px' }}>
            <p className={`${styles.dialogItem} defaultText`}>
              The Canadian Healing Quilt is operated by Canadian Virtual Hospice, a registered
              charity.
            </p>
            <p className={`${styles.dialogItem} defaultText`}>
              By using the Canadian Healing Quilt you are agreeing to the{' '}
              <a
                href="https://www.virtualhospice.ca/en_US/Main+Site+Navigation/Right+Footer+Navigation/Legal+Notices.aspx"
                target="_blank"
                rel="noreferrer"
              >
                Legal Notices / Privacy Policy.
              </a>
            </p>
            <p className={`${styles.dialogItem} defaultText`}>
              By using the Canadian Healing Quilt, you also confirm:
            </p>
            <ul className={styles.privacyList}>
              <li className={`${styles.list_item} defaultText`}>
                <span className={styles.list_dot}></span>
                if you create a Healing Quilt, that you are or have the permission of the
                administrator or representative of the person you are commemorating;
              </li>
              <li className={`${styles.list_item} defaultText`}>
                <span className={styles.list_dot}></span>
                if you post to a Healing Quilt, that you have the right to post the photos and
                contents you are posting to a Healing Quilt; and
              </li>
              <li className={`${styles.list_item} defaultText`}>
                <span className={styles.list_dot}></span>
                your use of the Canadian Healing Quilt will not be exploitive, obscene, harmful,
                threatening, abusive, harassing, hateful, defamatory, sexually explicit or
                pornographic, violent, inflammatory, or discriminatory based on race, sex, religion,
                nationality, disability, sexual orientation, or age or other such legally prohibited
                ground or be otherwise objectionable, such determination to be made in Canadian
                Virtual Hospice’s sole discretion.
              </li>
            </ul>
            <p className={`${styles.dialogItem} defaultText`}>
              For more information, please see the{' '}
              <a
                href="https://www.virtualhospice.ca/en_US/Main+Site+Navigation/Right+Footer+Navigation/Legal+Notices.aspx"
                target="_blank"
                rel="noreferrer"
              >
                Legal Notices / Privacy Policy
              </a>
              .
            </p>
            <p className={`${styles.dialogItem} defaultText`}>
              If you have any questions about the Canadian Healing Quilt or the privacy practices of
              Canadian Virtual Hospice, please contact{' '}
              <a href="mailto:info@virtualhospice.ca?subject=I have questions about the Canadian Healing Quilt">
                info@virtualhospice.ca
              </a>
              .
            </p>
          </DialogContent>
          <DialogActions sx={{ padding: '25px' }}>
            <button className="customButton" onClick={() => setIsShowPrivacy(false)}>
              {translates?.signUp?.privacyBtnLabelClose || 'Close'}
            </button>
            <button
              className="customButton"
              onClick={() => {
                setIsAgreed(true);
                setTermsIsRed(false);
                setIsShowPrivacy(false);
              }}
            >
              {translates?.signUp?.privacyBtnLabelAgree || 'Agree'}
            </button>
          </DialogActions>
        </Dialog>
        <Loader loading={isLoading} isWhite={false} />
      </div>
    </section>
  );
};

export default SignUp;
