import { FC, useEffect, useRef, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { useAuth } from 'providers/auth-provider';
import { useTranslates } from 'providers/translates';
import { RoutesName } from 'routs';
import { useAppSelector, useRedirectAndScroll } from '../../hooks';
import { getCookie } from '../../utilities';
import { CreateQuiltButton } from './components/CreateQuiltButton';
import { DesktopMenu } from './components/DesktopMenu';
import { MobileMenu } from './components/MobileMenu';
import './header.scss';

interface HeaderProps {}

const initialHeaderItemsController = {
  language: false,
  howToUse: false,
  support: false,
  myAccount: false,
  donate: false,
  aboutUs: false,
  contactUs: false,
  icons: false,
};

const Header: FC<HeaderProps> = () => {
  const isSave = useAppSelector((state) => state.notify.isSave);
  const { onlyRedirect } = useRedirectAndScroll({});
  const [searchParams] = useSearchParams();
  const { user } = useAuth();
  const { translates } = useTranslates();
  const location = useLocation();
  const { logout } = useAuth();
  const headerButtonsRef = useRef<HTMLDivElement | null>(null);

  const [headerItemsController, setHeaderItemsController] = useState(initialHeaderItemsController);

  const actionLogout = () => {
    isSave && logout();
    onlyRedirect(location.pathname, true);
  };

  // logic for inviting users
  useEffect(() => {
    if (user?.isInvitedUser) {
      const quiltId = getCookie('quiltId');
      const step = searchParams.get('step');
      const allowPages = ['login', 'signup', 'howtouse', 'resources', 'search'];

      if (!quiltId) return;

      const isCanRedirect = allowPages.some(
        (page) => location.pathname.includes(page) || location.pathname === '/'
      );

      if (!step && !isCanRedirect) {
        onlyRedirect(`/update/${quiltId}?step=3`);
      }
    }
  }, [user?.isInvitedUser, location]);

  useEffect(() => {
    if (!location?.pathname) return;

    if (user?.isInvitedUser) {
      setHeaderItemsController(() => {
        return {
          language: true,
          howToUse: true,
          support: true,
          myAccount: false,
          donate: false,
          aboutUs: false,
          contactUs: false,
          icons: false,
        };
      });
      return;
    }

    switch (location.pathname) {
      case '/':
        setHeaderItemsController(() => {
          return {
            language: true,
            howToUse: true,
            support: true,
            myAccount: true,
            donate: true,
            aboutUs: false,
            contactUs: false,
            icons: false,
          };
        });
        break;
      default:
        setHeaderItemsController(() => {
          return {
            language: true,
            howToUse: true,
            support: true,
            myAccount: true,
            donate: false,
            aboutUs: false,
            contactUs: false,
            icons: false,
          };
        });
    }
  }, [location]);

  return (
    <header className="header">
      <div className="center">
        <DesktopMenu 
          headerItemsController={headerItemsController} 
          actionLogout={actionLogout}
        />
      </div>

      <Box
        sx={{
          minHeight: '1px',
          background: "url('/img/general/line-small.png') repeat-x",
        }}
      ></Box>

      <div className="center">
        <div className="header__content">
          <div className='logo-and-menu-toggle'>
            <div
              onClick={() => onlyRedirect('/')}
              className="logo"
              style={{
                cursor: 'pointer',
              }}
            >
              <img 
                src="/img/general/logo.png" 
                alt="logo" 
              />
            </div>
            <MobileMenu 
              headerItemsController={headerItemsController} 
              actionLogout={actionLogout}
              headerButtonsRef={headerButtonsRef}
            />
          </div>
          <div 
            ref={headerButtonsRef} 
            className="header__wrapper"
            style={{display: 'flex'}}
          >
            <div className="header__buttons">
              <div>
                <span
                  className="header__button"
                  onClick={() => onlyRedirect(RoutesName.SearchQuilt)}
                >
                  {translates?.header?.searchQuilts || 'Search Quilts'}
                </span>
              </div>
              <div className='header__button-delimitiier'></div>
              <div className="header__button">
                <CreateQuiltButton />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
