import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  CircularProgress,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import moment from 'moment';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { colors } from 'utilities';
import { AdminUserQuilt, OwnerObject } from 'api/http-client/types';
import { QuiltSortField, QuiltTableHeader } from './const';
import { LockAndDeleteBox } from '../LockAndDeleteBox';
import { QuiltDetails } from './QuiltDetails';
import { SortBy } from '../UserTable/const';
import { ValueOf } from 'common/generic/value-of';

interface QuiltTableProps {
  visibleRows: AdminUserQuilt[];
  page: number;
  count: number;
  rowsPerPage: number;
  loading: boolean;
  userId?: string;
  ownerName?: string;
  handleChangePage: (newPage: number, sortBy: ValueOf<typeof SortBy>, sortField: ValueOf<typeof QuiltSortField>) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onQuiltRefetch: ({ sortBy, sortField }: { sortBy: ValueOf<typeof SortBy>; sortField: string }) => void;
}

export interface IQuiltTableContent {
  quiltId: number;
  quiltFullName: string;
  dateCreated: string;
  datePublished: string;
  dateUpdated: string;
  isPublic: string;
  tributesEnabled: string;
  finalImage: string | undefined;
  contributors: AdminUserQuilt['contributors'];
  locked: boolean;
  deleted: boolean;
  owner?: OwnerObject;
  ownerName?: string;
  reasonForDelete?: string;
  reasonForLock?: string;
  quiltStatus: string;
}

const tableCellStyle = {
  fontSize: {
    xs: 16,
    sm: 18,
    lg: 20,
  },
};

export const QuiltTable: React.FC<QuiltTableProps> = ({
  visibleRows,
  page,
  count,
  rowsPerPage,
  loading,
  userId,
  ownerName,
  handleChangePage,
  handleChangeRowsPerPage,
  onQuiltRefetch,
}) => {
  const [showQuiltDetails, setShowQuiltDetails] = useState({
    status: false,
    quiltId: -1,
  });
  const quiltTableContainer = useRef<HTMLDivElement | null>(null);

  const [order, setOrder] = useState<ValueOf<typeof SortBy>>(SortBy.DESC);
  const [orderBy, setOrderBy] = useState<ValueOf<typeof QuiltSortField>>('dateCreated');

  const quiltTableContent: IQuiltTableContent[] = useMemo(
    () =>
      visibleRows.map((row) => ({
        quiltId: row.id,
        quiltFullName: `${row.firstName} ${row.lastName}`,
        dateCreated: moment(row.dateCreated || '').format('MM/DD/YYYY'),
        datePublished: row.datePublished ? moment(row.datePublished).format('MM/DD/YYYY') : '',
        dateUpdated: moment(row.dateUpdated).format('MM/DD/YYYY'),
        isPublic: row.public ? 'Public' : 'Private',
        tributesEnabled: row.tributesEnabled ? 'Enabled' : 'Disabled',
        finalImage: row.finalImage?.full,
        contributors: row.contributors,
        locked: row.locked,
        deleted: row.deleted,
        owner: row.owner,
        ownerName: row.owner?.userFullName,
        reasonForDelete: row?.reasonForDelete || '',
        reasonForLock: row?.reasonForLock || '',
        quiltStatus: row.quiltStatus,
      })),
    [visibleRows]
  );

  useEffect(() => {
    setShowQuiltDetails({ status: false, quiltId: 0 });
  }, [loading]);

  const createSortHandler = (property: string) => () => {
    const isAsc = orderBy === property && order === SortBy.ASC;
    onQuiltRefetch({ sortBy: isAsc ? SortBy.DESC : SortBy.ASC, sortField: property });
    setOrder(isAsc ? SortBy.DESC : SortBy.ASC);
    setOrderBy(property as ValueOf<typeof QuiltSortField>);
  };

  const scrollToLeft = () => {
    quiltTableContainer.current?.scrollTo({ left: 0, behavior: 'smooth' });
  };

  return (
    <section>
      <TableContainer ref={quiltTableContainer} sx={{ mt: 6 }}>
        <Table sx={{ minWidth: 650 }} aria-label="quilts table">
          <TableHead>
            <TableRow>
              {QuiltTableHeader.map((header) => (
                <TableCell
                  key={header.id}
                  align={header.align}
                  sx={{
                    width: { xs: 'auto', lg: header.width },
                    fontSize: { xs: 16, sm: 20, lg: 24 },
                    color: colors.dark_blue,
                    fontWeight: 700,
                  }}
                >
                  {header.id === 'button' ? (
                    header.label
                  ) : (
                    <TableSortLabel
                      active={orderBy === header.id}
                      direction={orderBy === header.id ? order : SortBy.ASC}
                      onClick={createSortHandler(header.id)}
                    >
                      {header.label}
                      {orderBy === header.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === SortBy.DESC ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell align="center" colSpan={6} sx={tableCellStyle}>
                  <CircularProgress color="inherit" />
                </TableCell>
              </TableRow>
            ) : quiltTableContent.length ? (
              quiltTableContent.map((row) => (
                <React.Fragment key={row.quiltId}>
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        ...tableCellStyle,
                        fontWeight: 700,
                        minWidth: { xs: '200px', lg: '250px' },
                      }}
                    >
                      {row.quiltFullName}
                    </TableCell>
                    <TableCell align="center" sx={{ minWidth: '250px', ...tableCellStyle }}>
                      {ownerName ? ownerName : row.ownerName}
                    </TableCell>
                    <TableCell align="center" sx={{ minWidth: '250px', ...tableCellStyle }}>
                      {row.dateCreated}
                    </TableCell>
                    <TableCell align="center" sx={{ minWidth: '250px', ...tableCellStyle }}>
                      {row.datePublished ? row.datePublished : 'Not Published'}
                    </TableCell>
                    <TableCell align="center" sx={{ minWidth: '250px', ...tableCellStyle }}>
                      {row.dateUpdated}
                    </TableCell>
                    <TableCell align="center" sx={{ minWidth: '250px', ...tableCellStyle }}>
                      {row.isPublic}
                    </TableCell>
                    <TableCell align="center" sx={{ minWidth: '250px', ...tableCellStyle }}>
                      {row.tributesEnabled}
                    </TableCell>
                    <TableCell align="center" sx={{ minWidth: '250px', ...tableCellStyle }}>
                      {row.quiltStatus}
                    </TableCell>
                    <TableCell align="center" sx={tableCellStyle}>
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                          showQuiltDetails.quiltId !== row.quiltId && scrollToLeft();

                          setShowQuiltDetails((prev) => ({
                            status: prev.quiltId === row.quiltId ? false : true,
                            quiltId: prev.quiltId === row.quiltId ? -1 : row.quiltId,
                          }));
                        }}
                      >
                        {showQuiltDetails.status && showQuiltDetails.quiltId === row.quiltId ? (
                          <ArrowDropUpIcon fontSize="large" />
                        ) : (
                          <ArrowDropDownIcon fontSize="large" />
                        )}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    style={{
                      visibility:
                        showQuiltDetails.status && showQuiltDetails.quiltId === row.quiltId
                          ? 'visible'
                          : 'collapse',
                    }}
                  >
                    <TableCell style={{ padding: 0, width: '100%' }} colSpan={7}>
                      <Collapse in={showQuiltDetails.status} timeout={1000}>
                        <QuiltDetails row={row} userId={userId} contributors={row.contributors} />
                        <LockAndDeleteBox
                          id={showQuiltDetails.quiltId}
                          row={row}
                          isUser={false}
                          userId={userId}
                        />
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={6} sx={tableCellStyle}>
                  No quilts to display
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {count > 5 && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(_, page) => handleChangePage(page, order, orderBy)}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </section>
  );
};
