import { DefaultDialog } from "components/DefaultDialog";
import { useRedirectAndScroll } from "hooks";
import { useAuth } from "providers/auth-provider";
import { useTranslates } from "providers/translates";
import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { RoutesName } from "routs";
import styles from './styles.module.scss';

const CreateQuiltButton: React.FC = () => {
  const { onlyRedirect } = useRedirectAndScroll({});
  const { id } = useParams();
  const { isAuthorized, user } = useAuth();
  const { translates } = useTranslates();
  const location = useLocation();

  const [open, setOpen] = useState(false);

  const handleConfirmDialogOpen = () => {
    setOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setOpen(false);
  };

  const goToNewQuilt = () => {
    user?.isInvitedUser
      ? onlyRedirect(RoutesName.SignUp)
      : onlyRedirect(`${RoutesName.CreateQuilt}?step=1`);
    handleConfirmDialogClose();
    window.location.reload();
  };

  return (
    <div>
      {location.pathname !== `/update/${id}` ? (
        <span
          onClick={() => {
            isAuthorized && !user?.isInvitedUser
              ? onlyRedirect(`${RoutesName.CreateQuilt}?step=1`)
              : onlyRedirect(RoutesName.SignUp);
          }}
        >
          {translates?.header?.createQuilt || 'Create a Quilt'}
        </span>
      ) : (
        <>
          <span onClick={handleConfirmDialogOpen}>
            {translates?.header?.createQuilt || 'Create a Quilt'}
          </span>

          <DefaultDialog
            open={open}
            setDialog={handleConfirmDialogClose}
            title={
              translates?.header?.createANewQuiltConfirmDialogTitle ||
              'Are you sure you want to exit the editable quilt?'
            }
            text={
              translates?.header?.createANewQuiltConfirmDialogContent ||
              'All unsaved changes may be lost!'
            }
            cancelBtnText={
              translates?.header?.createANewQuiltConfirmDialogConfirmBtnLabel || 'Stay'
            }
            confirmBtnText={
              translates?.header?.createANewQuiltConfirmDialogCancelBtnLabel ||
              'New quilt'
            }
            confirmAction={goToNewQuilt}
          />
        </>
      )}
    </div>
  );
}

export { CreateQuiltButton };
